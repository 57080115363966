"use strict"

import { swupTransitions } from './scripts/swup'
import { utils, getIndexPage } from './scripts/utils';
import { Cursor1 } from './scripts/cursors/cursor1';


export const setCursor = () => {
  const index = getIndexPage();
  new Cursor1(index);
}

window.addEventListener('load', async () => {
  await utils();
  swupTransitions();
});
